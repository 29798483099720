import React from 'react';
import Loading from '../../components/Loading';
import { useJsonApi } from '../../services/GlobalStore/GlobalStore';
import QuestionnairePayments from '../Questionnaire/QuestionnairePayments';
import { navigate, useLocation } from '@reach/router';
import translations from '../../translations/main.json';

const Payment = ({ uuidNumber }: { uuidNumber: string }): any => {
  const uuid = uuidNumber;
  const { href } = useLocation();

  const { data, loading, errors } = useJsonApi(
    `/submissionRequests/${uuid}`,
    {
      cachePolicy: 'no-cache',
      queryParams: {
        include: [
          ['questionnaire', 'questions', 'children'].join('.'),
          'claim',
        ],
      },
    },
    []
  );

  if (loading) {
    return (
      <div className="flex justify-center mt-40">
        <Loading />
      </div>
    );
  }

  if (data && !loading && !errors?.length) {
    return <QuestionnairePayments data={data} uuid={uuidNumber} />;
  }

  if (errors.length) {
    const url = href.replace(
      translations['cc4.seo.route.payment-confirmation'],
      translations['cc4.seo.route.payment-status']
    );
    navigate(url);
  }

  return null;
};

export default Payment;
