import React from 'react';
import { useIntl } from 'react-intl';
import Card from '../../components/Card';
import Container from '../../components/Container';
import StepTitle from '../../components/StepTitle';
import messages from './messages';

const NoPaymentConfirmationMessage: React.FC<{}> = () => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      <Card>
        <div className="flex justify-center sm:justify-between items-center">
          <StepTitle>
            {formatMessage(messages.noPaymentConfirmationMessageTitle)}
          </StepTitle>
        </div>
        <p className="mb-35 mt-0 mx-10 sm:mx-0 text-black text-center sm:text-left">
          {formatMessage(messages.noPaymentConfirmationMessageDescription)}
        </p>
        <p className="mb-35 mt-0 mx-10 sm:mx-0 text-black text-center sm:text-left">
          {formatMessage(messages.noPaymentConfirmationMessageSubDescription)}
        </p>
      </Card>
    </Container>
  );
};

export default NoPaymentConfirmationMessage;
