import React from 'react';

// Components
import PageTransition from '../components/PageTransition';
import Payment from '../views/Payments/Payment';
import NoPaymentConfirmationMessage from '../views/Payments/NoPaymentConfirmationMessage';
import PaymentConfirmationDetails from '../views/Payments/PaymentConfirmationtDetails';
import PaymentStatus from '../views/Payments/PaymentStatus';
import NotFoundPage from '../pages/404';

// Translations
import translations from '../translations/main.json';

const PaymentPage = () => {
  return (
    <PageTransition basepath={'/'} showStepper={false} hideMenu={true}>
      <Payment
        path={
          translations['cc4.seo.route.payment-confirmation'] + '/:uuidNumber'
        }
      />
      <NoPaymentConfirmationMessage
        path={
          translations['cc4.seo.route.no-payment-received'] + '/:uuidNumber'
        }
      />
      <PaymentConfirmationDetails
        path={translations['cc4.seo.route.payment-received'] + '/:uuidNumber'}
      />
      <PaymentStatus
        path={translations['cc4.seo.route.payment-status'] + '/:uuidNumber'}
      />
      <NotFoundPage default />
    </PageTransition>
  );
};

export default PaymentPage;
