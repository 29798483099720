import React from 'react';
import { useIntl } from 'react-intl';
import Card from '../../components/Card';
import Container from '../../components/Container';
import StepTitle from '../../components/StepTitle';
import messages from './messages';
import useSiteMetadata from '../../hooks/useSiteConfig';

const PaymentStatus = () => {
  const { formatMessage } = useIntl();
  const { calculatorDomain } = useSiteMetadata();

  return (
    <Container>
      <Card>
        <div className="flex justify-center sm:justify-between items-center">
          <StepTitle>{formatMessage(messages.paymentStatusTitle)}</StepTitle>
        </div>
        <p className="mb-35 mt-0 mx-10 sm:mx-0 text-black text-center sm:text-left">
          {formatMessage(messages.paymentStatusDescription)}
          {formatMessage(messages.paymentStatusSubDescription, {
            site_domain: calculatorDomain,
          })}
        </p>
        <p className="mb-35 mt-0 mx-10 sm:mx-0 text-black text-center sm:text-left">
          {formatMessage(messages.paymentStatusNote, {
            // eslint-disable-next-line react/no-multi-comp
            bold: (...chunks) => <b>{chunks}</b>,
          })}
        </p>
      </Card>
    </Container>
  );
};

export default PaymentStatus;
