import { defineMessages } from 'react-intl';

export default defineMessages({
  // Payment confirmation
  paymentConfirmationTitle: {
    id: 'cc4.shareable-payment-confirmation-details.title',
    defaultMessage: 'Your payment confirmation details',
  },
  paymentConfirmationDescription: {
    id: 'cc4.shareable-payment-confirmation-details.description',
    defaultMessage:
      'Thank your for confirming that you have been paid out by the airline in the meantime. Before we are able to finalize your claim, can you please confirm the following details:',
  },
  paymentNoMatchConfirmationDescription: {
    id: 'cc4.shareable-payment-no-match-confirmation-details.description',
    defaultMessage:
      'Thank your for confirming that you have been paid out by the airline in the meantime. A member of our team will contact you as soon as possible so that we can finalize your claim.',
  },
  paymentNoMatchConfirmationSubDescription: {
    id: 'cc4.shareable-payment-no-match-confirmation-details.sub.description',
    defaultMessage: 'For now we would like to wish you a pleasant day.',
  },
  paymentConfirmationDateLabel: {
    id: 'cc4.form.shareable-payment-confirmation-details.label.date',
    defaultMessage: 'On which date did you receive the payment',
  },
  paymentConfirmationAmountLabel: {
    id: 'cc4.form.shareable-payment-confirmation-details.label.amount',
    defaultMessage: 'What is the amount you received',
  },
  paymentConfirmationTextArea: {
    id: 'cc4.form.shareable-payment-confirmation-details.label.textarea',
    defaultMessage: 'Tell us more (optional)',
  },
  paymentConfirmationMessageDescription: {
    id: 'cc4.shareable-payment-confirmation-message.description',
    defaultMessage:
      'Thank you for confirming that you have been paid out by the airline in the meantime. A member of our team will contact you as soon as possible so that we can finalize your claim.',
  },
  paymentConfirmationMessageSubDescription: {
    id: 'cc4.shareable-payment-confirmation-message.sub.description',
    defaultMessage: 'For now we would like to wish you a pleasant day.',
  },
  noPaymentConfirmationMessageTitle: {
    id: 'cc4.shareable-no-payment-confirmation-message.title',
    defaultMessage: 'No payment received yet',
  },
  noPaymentConfirmationMessageDescription: {
    id: 'cc4.shareable-no-payment-confirmation-message.description',
    defaultMessage:
      'Thank you for confirming that you have yet to receive the payment from the airline. Our team will now chase this up with the airline so that we can get your money as soon as possible. Rest assured that a member of our team will contact you directly as soon as we know more.',
  },
  noPaymentConfirmationMessageSubDescription: {
    id: 'cc4.shareable-no-payment-confirmation-message.sub.description',
    defaultMessage:
      'For now we would appreciate it if you could let us know if the payment has been received in the meantime.',
  },

  // Payment status
  paymentStatusTitle: {
    id: 'cc4.shareable-payment-status-details.title',
    defineMessage: 'No longer possible to reply',
  },
  paymentStatusDescription: {
    id: 'cc4.shareable-payment-status-details.description',
    defaultMessage:
      'You have already replied using the buttons in the past and/or your claim has been moved to another stage.',
  },
  paymentStatusSubDescription: {
    id: 'cc4.shareable-payment-status-details.sub.description',
    defaultMessage:
      'Did something change in the meantime? Then please inform us about it by contacting us via your online account on {site_domain}.',
  },
  paymentStatusNote: {
    id: 'cc4.shareable-payment-status-details.note',
    defaultMessage:
      "<bold>Important:</bold> Your claim status might have changed in the meantime. To make sure you're up to date, please search your inbox for any recent e-mails from us and check the status of your claim in your online account.",
  },

  submitButtonText: {
    id: 'cc4.shareable-payment-confirmation-details.submit-button-text',
    defaultMessage: 'Continue',
  },
});
